@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Avenir-black;
    src: url('./lib/fonts/Avenir-black.otf');
  }

  @font-face {
    font-family: Avenir-Book;
    src: url('./lib/fonts/Avenir-Book.otf');
  }

  @font-face {
    font-family: Avenir-medium;
    src: url('./lib/fonts/Avenir-medium.otf');
  }

  @font-face {
    font-family: sf-compact-display-medium;
    src: url('./lib/fonts/sf-compact-display-medium.otf');
  }

  @font-face {
    font-family: sf-compact-display-thin;
    src: url('./lib/fonts/sf-compact-display-thin.otf');
  }
}

.vi__wrapper{
  width: 100% !important;
}

.vi:focus{
  --tw-ring-shadow: none;
}

.rccs__card--unknown > div, 
.rccs__card--front,
.rccs__card--visa .rccs__card__background, 
.rccs__card--visa-electron .rccs__card__background{
  background: rgb(34,34,142) !important;
  background: linear-gradient(25deg, #0f509e, #070d59 100%) !important;
}

.rccs__expiry__valid{
  margin-bottom: 2px !important;
}

.rccs--focused{
  font-weight: 400 !important;
}